import helpText from '@/views/help/betRules/helpText.json'
import pages from '@/views/help/betRules/pages.json'

export default {
  //全局
  'global.home': 'Trang chủ',
  'global.sports': 'Thể thao',
  'global.electronic': 'Slots',
  'global.gaming': 'E-Sports',
  'global.lottery': 'Xổ số',
  'global.realPerson': 'Casino',
  'global.cards': 'Game bài',
  'global.fishing': 'Bắn cá',
  'global.cockfighting': 'Đá gà',
  'global.promotion': 'Giảm giá',
  'global.order': 'Đơn cược',
  'global.profile': 'Của tôi',
  'global.deposit': 'Nạp tiền',
  'global.withdraw': 'Rút tiền',
  'global.helpCenter': 'Trung tâm trợ giúp',
  'global.confirm': 'Xác nhận',
  'global.cancel': 'Hủy',
  'global.delete': 'Xóa',
  'global.sentSuccessfully': 'Gửi thành công!',
  'global.operationSuccessful': 'Thao tác thành công!',
  'global.operationsFrequent': 'Thao tác quá thường xuyên!',
  'global.copySuccessful': 'Sao chép thành công!',
  'global.contactService': 'Liên hệ với dịch vụ khách hàng',
  'global.customerService': 'Dịch vụ khách hàng trực tuyến',
  'global.contactServiceTips': 'Nếu bạn có bất kỳ câu hỏi, vui lòng liên hệ với dịch vụ khách hàng trực tuyến của chúng tôi.',
  'global.noData': 'Không có dữ liệu',
  'global.reload': 'Tải lại',
  'global.failedToLoad': 'Tải thất bại',
  'global.failedToLoadTips': 'Hiện không có dữ liệu để truy cập',
  'global.loading': 'Đang tải',
  'global.tips': 'Nhắc nhở',
  'global.telephone.placeholder': 'Vui lòng nhập số điện thoại',
  'global.telephone.validate': 'Vui lòng nhập đúng định dạng điện thoại di động',
  'global.captchaCode.isSent': 'Vui lòng nhận mã xác minh trước',
  'global.captchaCode.placeholder': 'Vui lòng nhập mã xác minh',
  'global.captchaCode.validate': 'Vui lòng nhập mã xác minh',
  'global.captchaCode.sentSuccessfully': 'Mã xác minh đã được gửi thành công!',
  'global.username.placeholder': 'Vui lòng nhập 6-16 chữ cái và số',
  'global.username.validate': 'Vui lòng nhập 6-16 chữ cái và số',
  'global.password.placeholder': 'Vui lòng nhập mật khẩu từ 8 đến 20 ký tự và số hoặc ký hiệu',
  'global.password.validate': 'Vui lòng nhập mật khẩu từ 8 đến 20 ký tự và số hoặc ký hiệu',
  'global.navigation.frontpage': 'Trang chủ',
  'global.navigation.physical': 'Thể thao',
  'global.navigation.discount': 'Khuyến mãi',
  'global.navigation.order': 'Lịch sử',
  'global.navigation.myinfo': 'Tài khoản',
  'global.noMore': 'Không còn nhiều hơn nữa',
  'global.all': 'Toàn bộ',
  'global.add': 'cộng thêm',
  'global.exitAppToast': 'Bấm thêm 1 lần để thoát ứng dụng',
  'global.defaultErrorToast': 'Xin lỗi, có lỗi xảy ra, vui lòng thử lại sau!',
  'global.openWindow.errorTips': 'Mở cửa sổ thất bại,xin hãy tắt chế độ chặn cửa sổ trên trình duyệt',

  // 全局组件
  'global.captchaButton.getCode': 'Nhận mã xác minh',
  'global.captchaButton.sendingCode': 'Đang gửi mã xác minh',
  'global.captchaButton.resend': 'Gửi lại',
  'global.captchaButton.countdown': '{count} s (gửi lại)',
  'global.captchaPhone.title': 'Xác minh số điện thoại',
  'global.captchaPhone.tips': 'Để đảm bảo an toàn cho tài khoản và tiền của bạn, vui lòng hoàn tất xác minh số điện thoại trước',
  'global.captchaPhone.service': 'Nếu bạn cần thay đổi số điện thoại, vui lòng {e}',

  // banner
  'home.banner.readMore': 'xem thêm',

  // 登录、注册、忘记密码
  'login.title': 'Đăng nhập',
  'login.loginPhone': 'Đăng nhập bằng điện thoại',
  'login.loginAccount': 'Đăng nhập bằng tài khoản',
  'login.loginSuccessToast': 'Đăng nhập thành công',
  'login.alreadyHaveAccount': 'Đã có tài khoản? Vào{e}',
  'login.username.placeholder': 'Vui lòng nhập tên người dùng',
  'login.password.placeholder': 'Mật khẩu',
  'login.confirmPassword.placeholder': 'Xác nhận mật khẩu',
  'register.register': 'Đăng ký',
  'register.title': 'Đăng ký tài khoản',
  'register.success': 'Đăng ký thành công!',
  'register.successPopup.primaryText': 'Chúc mừng bạn đã đăng kí tài khoản AB77 thành công.',
  'register.successPopup.subText': 'Hãy nạp tiền lần đầu để nhận thưởng lên tới 3,000,000 VNĐ chỉ với 6 vòng cược.',
  'register.successPopup.button': 'NẠP TIỀN NGAY',
  'forget.title': 'Quên mật khẩu',
  'forget.checkPassword.placeholder': 'Vui lòng xác nhận mật khẩu mới',
  'forget.checkPassword.validate': 'Nhập mật khẩu hai lần không khớp!',
  'login.frequentErrors': 'Mã xác minh SMS quá nhiều lần không chính xác và đã hạn chế sử dụng\n{m} phút.',
  'login.tab.guest': 'Chưa là thành viên',
  'login.tab.member': 'Đã là thành viên',
  'login.phone.title': 'Đăng nhập bằng số điện thoại hoặc tên đăng nhập',
  'login.username.title': 'Vui lòng điền thông tin của bạn để hoàn tất đăng ký',
  'reset.password': 'đặt lại mật khẩu',
  'reset.password.rule': 'Mật khẩu bao gồm 8-20 chữ cái, số hoặc ký hiệu',
  'reset.change': 'thay đổi',
  'login.username.rule': 'Tên đăng nhập gồm 6-16 ký tự gồm chữ cái và số',
  'login.welcome': 'Chào mừng đến với AB77',
  'login.form.welcome': '',
  'login.form.guest': 'Xin chào !',
  'phone.errorMessages': 'Nhập số điện thoại di động gồm 10 chữ số của bạn',
  'phone.code.errorMessages': 'Nhập mã xác minh gồm 4 chữ số',
  'placeholder.password': 'Mật khẩu',
  'placeholder.username': 'Tên đăng nhập',
  'register.agentCode': 'Mã đại lý',
  'register.agentCode.placeholder': 'Nếu bạn có mã đại lý, vui lòng điền vào (tùy chọn)',
  'register.agentCode.errorMessage': 'Mã đại lý gồm 4-10 ký tự chữ cái và số',
  'register.password.notMatch': 'Mật khẩu không trùng khớp',
  'register.phone.optional': 'tùy chọn',

  // 首页
  'home.notLogin.welcome': 'Xin chào, Người dùng thân mến',
  'home.notLogin.login': 'Vui lòng đăng nhập',
  'home.game.maintenance': 'BẢO TRÌ',
  'home.game.comingSoon': 'Sắp ra mắt',
  'home.game.recommend': 'HOT',
  'home.game.new': 'Mới',
  'home.game.enterGame': 'Lập tức vào trò chơi',
  'home.game.maintenanceToast': 'Trò chơi này đang được bảo trì!',
  'home.game.comingSoonToast': 'Sắp ra mắt vui lòng chờ đợi!',
  'home.game.transferError': 'Nếu trò chơi bạn đã vào không hiển thị đúng số dư, bạn có thể đóng trang trò chơi và thử vào lại',
  'home.game.sublist': 'Danh sách trò chơi',
  'home.banner.explore': 'Khám phá ngay',
  'home.banner.jump': 'xem thêm',
  'home.title.loginText': 'Chào mừng đến với chúng tôi!',
  'home.tab.banner': 'Tin nổi bật',
  'home.tab.category': 'Danh mục trò chơi',
  'home.title.walletTop': 'Ví tiền ',

  // 首页快速投注
  'home.quickBet.loadMore': 'Xem thêm',
  'home.quickBet.createBetSlip': 'Tạo phiếu cược',
  'home.quickBet.betSlip': 'Cược đơn',
  'home.quickBet.betSlip.balance': 'Số dư',
  'home.quickBet.betSlip.bet': 'ĐẶT CƯỢC',
  'home.quickBet.betSlip.confirm': 'Xác nhận',
  'home.quickBet.betSlip.bettingAmount.limit': 'Hạn mức: ',
  'home.quickBet.betSlip.bettingOdds': 'Tỷ lệ cược',
  'home.quickBet.betSlip.bettingAmount': 'Số tiền cược',
  'home.quickBet.betSlip.winnable': 'Có thể thắng',
  'home.quickBet.betSlip.totalAmount': 'Tổng tiền cược',
  'home.quickBet.betSlip.confirming': 'Chờ xác nhận',
  'home.quickBet.betSlip.confirmed': 'Đã xác nhận',
  'home.quickBet.betSlip.rejected': 'Đặt cược không thành công',
  'home.quickBet.betSlip.successful': 'Cược thành công',
  'home.quickBet.betSlip.closed': 'Không được phép đặt cược, Kèo đã đóng',
  'home.quickBet.inProgress': 'Đang tiến hành',

  // 充值、提现
  'wallet.name': 'VÍ',
  'wallet.step': 'BƯỚC',
  'wallet.step1.title.up': 'CHỌN HÌNH THỨC THANH TOÁN',
  'wallet.step2.title.up': 'NHẬP SỐ TIỀN',
  'wallet.step3.title.up': 'ĐIỀN TÊN ĐĂNG KÝ CỦA BẠN',
  'wallet.step1.title.down': 'CHỌN TÀI KHOẢN NHẬN TIỀN',
  'wallet.step2.title.down': 'NHẬP SỐ TIỀN',
  'wallet.up.tips': 'đảm bảo số tiền thanh toán của bạn giống với số tiền đặt hàng',
  'wallet.amountInput.placeholder': 'Vui lòng nhập số tiền {typeName}',
  'wallet.amountInput.limit': 'Giới hạn {minAmount}～{maxAmount} Đồng',
  'wallet.balancePanel.title': 'số dư khả dụng',
  'wallet.balancePanel.withdrawBalance': 'Số tiền có thể rút:',
  'wallet.information.noOrder': 'Hiện không có đơn hàng',
  'wallet.information.orderInfo': 'Thông tin đơn hàng',
  'wallet.information.orderId': 'Mã số đơn',

  'wallet.deposit.doc': 'Hướng dẫn nạp tiền',
  'wallet.deposit.formPayLabel': 'Phương thức nạp tiền',
  'wallet.deposit.formAmountLabel': 'NHẬP SỐ TIỀN',
  'wallet.deposit.formInfoLabel': 'Thông tin đầy đủ',
  'wallet.deposit.formInfoPlaceholder': 'Vui lòng nhập tên chủ tài khoản chuyển khoản',
  'wallet.deposit.amountValidate': 'Vui lòng điền số tiền nạp',
  'wallet.deposit.limitValidate': 'Vui lòng điền số tiền trong giới hạn',
  'wallet.deposit.nameValidate': 'Vui lòng điền tên chủ thẻ',
  'wallet.deposit.nameValidateReg': 'Vui lòng nhập tên chủ thẻ của thẻ chuyển khoản theo đúng định dạng!',
  'wallet.deposit.submit': 'Lập tức nạp tiền',
  'wallet.deposit.noDataTitle': 'Hiện chưa có phương thức nạp tiền khả dụng',
  'wallet.deposit.noDataText': 'Chúng tôi xin lỗi vì sự bất tiện này',
  'wallet.deposit.information.payInfo': 'Thông tin thanh toán',
  'wallet.deposit.information.payQrCode': 'Mã QR của ngân hàng thụ hưởng',
  'wallet.deposit.information.cancel': 'Hủy nạp tiền hiện tại',
  'wallet.deposit.information.confirmCancel': 'Tiếp tục hủy',
  'wallet.deposit.information.cancelTips': 'Nếu bạn đã hoàn tất thanh toán, vui lòng không hủy, nếu không bạn có thể bị mất số tiền trong giao dịch này.',
  'wallet.deposit.information.cancelSubTips': 'Hãy kiên nhẫn chờ đợi, chúng tôi sẽ xử lý trong thời gian sớm nhất',
  'wallet.deposit.information.inProgress': 'Đang nạp tiền',
  'wallet.deposit.information.time': 'Thời gian nạp tiền',
  'wallet.deposit.information.owner': 'Tên tài khoản thụ hưởng',
  'wallet.deposit.information.bankcardNo': 'Số tài khoản thụ hưởng',
  'wallet.deposit.information.bankName': 'Ngân hàng thụ hưởng',
  'wallet.deposit.information.bankBranch': 'Tên chi nhánh',
  'wallet.deposit.information.keyword': 'Lưu ý',
  'wallet.deposit.information.countdownText': 'Vui lòng hoàn tất thanh toán trong {minute} phút {second} giây, số tiền thanh toán phải khớp với số tiền đơn hàng',
  'wallet.deposit.information.countDown': 'Vui lòng hoàn tất thanh toán trong thời gian',
  'wallet.deposit.information.popup.comfirm': 'CÓ',
  'wallet.deposit.information.popup.cancel': 'KHÔNG',
  'wallet.deposit.information.popup.usdtAmount': 'Chuyển đến USDT',
  'wallet.deposit.UsdtAmount': 'số tiền USDT',
  'wallet.deposit.rechargeAddress': 'Địa chỉ nạp tiền',

  'wallet.withdraw.manual': 'Thuyết minh rút tiền',
  'wallet.withdraw.doc': 'Hướng dẫn rút tiền',
  'wallet.withdraw.bankCard': 'Ngân hàng nhận',
  'wallet.withdraw.noCard': 'Bạn chưa thêm thẻ ngân hàng',
  'wallet.withdraw.amount': 'Số tiền rút',
  'wallet.withdraw.information.inProgress': 'Đang tiến hành rút tiền',
  'wallet.withdraw.information.bankCardNo': 'Số thẻ rút tiền',
  'wallet.withdraw.information.time': 'Thời gian rút tiền',
  'wallet.withdraw.information.bankName': 'Ngân hàng rút tiền',
  'wallet.withdraw.unableWithdraw': 'Bạn hiện chưa thể rút tiền',
  'wallet.withdraw.leftAmount': 'Doanh thu còn lại',
  'wallet.withdraw.unableWithdrawForLevel': 'Cấp thành viên hiện tại không thể rút tiền',
  'wallet.withdraw.maintenance': 'Bảo trì rút tiền',
  'wallet.withdraw.noCountLimit': 'Đã đạt giới hạn rút tiền của ngày hôm nay, vui lòng thử lại vào ngày mai',
  'wallet.withdraw.noAmountLimit': 'Số tiền rút lần này không đủ và số dư rút hôm nay vẫn còn',
  'wallet.withdraw.prcoeed': 'nhấn vào đây để liên hệ với bộ phận hỗ trợ khách hàng của chúng tôi để tìm giải pháp trước khi bạn tiếp tục',
  'wallet.withdraw.withdrawAmount': 'Số tiền rút lần này',
  'wallet.withdraw.charge': 'Phí thủ tục',
  'wallet.withdraw.expectedReceipt': 'Dự kiến​đến',
  'wallet.withdraw.viewWithdrawManual': 'Xem thuyết minh rút tiền',
  'wallet.withdraw.amountValidate': 'Vui lòng điền số tiền rút',
  'wallet.withdraw.insufficientAmountValidate': 'Số tiền có thể rút không đủ',
  'wallet.withdraw.limitValidate': 'Vui lòng điền số tiền trong giới hạn',
  'wallet.CASHOUTAMOUNT': 'SỐ TIỀN RÚT',
  'wallet.RequestedAmount': 'Số tiền yêu cầu',
  'wallet.receivedAmount': 'Số tiền nhận được',
  'wallet.CashOutAmount': 'số tiền rút',
  'wallet.handlingfee': 'phí xử lý được áp dụng khi bạn rút tiền',
  'wallet.INSUFFICIENTBALANCE': 'SỐ DƯ TIỀN KHÔNG ĐỦ',
  'wallet.point': 'số dư tiền không đủ để thực hiện lệnh rút',
  'wallet.legend': 'nhấn vào đây để liên hệ với bộ phận hỗ trợ khách hàng của chúng tôi để tìm giải pháp trước khi bạn tiếp tục',
  'wallet.currentBalance': 'Số tiền rút sẵn có là',
  'wallet.LOADUNSUCCESSFUL': 'TẢI KHÔNG THÀNH CÔNG',
  'wallet.nodata': 'Hiện tại không có dữ liệu',
  'wallet.reload': 'TẢI LẠI',
  'wallet.GETHELP': 'TRỢ GIÚP',
  'wallet.noMethod': 'LỖI HỆ THỐNG',
  'wallet.noMethodTips': 'Chúng tôi xin lỗi vì bất kỳ sự bất tiện gây ra. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng trực tuyến của chúng tôi.',
  'wallet.amountInput.tips': 'vui lòng điền tên bạn đã đăng ký vào phương thức thanh toán bạn đã chọn',
  'wallet.withdraw.noCryptoCurrency': 'Bạn chưa thêm địa chỉ nhận tiền ảo',
  'wallet.withdraw.information.protocol': 'Giao thức',

  // 注单记录
  'betting.validStatement': 'Doanh thu hiệu lực:',
  'betting.unit': 'Đơn',
  'betting.paid': 'Đã kết toán',
  'betting.noPaid': 'Chưa kết toán',
  'betting.canceled': 'Đã hủy',
  'betting.coinType': 'Tiền tệ:',
  'betting.collusion': 'Cược kết hợp',
  'betting.principal': 'Tiền lần này:',
  'betting.validBet': 'Cược hợp lệ',
  'betting.betTime': 'Thời gian cược',
  'betting.betNumber': 'Số đơn cược',
  'betting.open': 'Mở rộng',
  'betting.close': 'Thu gọn',
  'betting.gameName': 'Tên trò chơi',
  'betting.result': 'Thắng thua',
  'betting.total': 'Tổng tính (đơn)',
  'betting.betContent': 'Nội dung cá cược',
  'betting.maxCanWinAmmount': 'Chiến thắng tối đa',
  'betting.noOpen': 'Chưa được vẽ',
  'betting.winning': 'Chiến thắng',
  'betting.noWin': 'Không thắng',
  'betting.cancel': 'Hủy bỏ',
  'betting.win': 'THẮNG',
  'betting.lose': 'THUA',
  'betting.halfLose': 'Thua một nửa',
  'betting.halfWin': 'Thắng một nửa',
  'betting.tie': 'Hòa',
  'betting.earlySettlement': 'Trước kết toán',
  'betting.noResults': 'Chưa kết toán',
  'betting.noData': 'Không có dữ liệu',
  'betting.history': 'LỊCH SỬ',

  'betting.summary.winlose': 'Số tiền',
  'betting.summary.amount': 'thắng/thua',
  'betting.summary.spend': 'Số tiền cược hợp lệ',

  'betting.order.title': 'ĐẶT CƯỢC',

  'betting.subGame': 'Game phụ',
  'betting.tableNumber': 'Mã số bàn',
  'betting.bureauNumber': 'Mã số ván',
  'betting.roomNumber': 'Mã số phòng',
  'betting.issue': 'Số kỳ',
  'betting.section': 'Số trận',
  'betting.raceName': 'Tên giải đấu',
  'betting.betrayal': 'Chi tiết cược',
  'betting.option': 'Tùy chọn',
  'betting.seatNumber': 'Số ghế',
  'betting.magnification': 'Bội số',
  'betting.odds': 'Tỷ lệ cược',
  'betting.multiple': 'Bội số',
  'betting.betAmount': 'Số tiền cược',

  // 收款地址
  'profile.payment.tips.title': 'Có thể thêm tối đa 5 thẻ ngân hàng',
  'profile.payment.bank.save': 'Thẻ ghi nợ',
  'profile.payment.noData': 'Chưa có địa chỉ nhận khoản',
  'profile.payment.title': 'Địa chỉ nhận khoản',
  'profile.payment.cardHolder': 'Chủ thẻ',
  'profile.payment.placeholder.comfirmSignature': 'Vui lòng đảm bảo nhập tên chủ thẻ',
  'profile.payment.placeholder.signature': 'Vui lòng điền tên chủ thẻ',
  'profile.payment.openingBank': 'Ngân hàng mở tài khoản',
  'profile.payment.openingBank.select': 'Chọn ngân hàng mở tài khoản',
  'profile.payment.pickCards': 'Chọn thẻ ngân hàng',
  'profile.payment.addCards.success': 'Thêm thẻ ngân hàng thành công',
  'profile.payment.realName': 'Tên',
  'profile.payment.cardNnumber': 'Số thẻ',
  'profile.payment.placeholder.cardNumber': 'Vui lòng điền số tài khoản',
  'profile.payment.tips.comfirm.title': 'Vui lòng xác nhận mọi thông tin chính xác chưa, sai thông tin sẽ không nhận được tiền vào tài khoản.',
  'profile.payment.bank': 'Thẻ ngân hàng',
  'profile.payment.hello': 'Xin chào',
  'profile.payment.comfirm.addCard': 'Xác nhận thêm thẻ ngân hàng',
  'profile.payment.tips.deleteCard.success': 'Xóa thẻ ngân hàng thành công',
  'profile.payment.addBank': 'Thêm tài khoản ngân hàng',
  'profile.payment.transaction': 'giao dịch',
  'profile.payment.transaction.step1': 'BƯỚC 1 >',
  'profile.payment.transaction.step2': 'BƯỚC 2 >',
  'profile.payment.transaction.step3': 'BƯỚC 3 >',
  'profile.payment.transaction.accountDetail': 'thông tin tài khoản',
  'profile.payment.transaciton.warning': 'tiền gửi sẽ bị từ chối, nếu chi tiết bạn điền khác với chi tiết bạn đã đăng ký từ ngân hàng',
  'profile.payment.transaciton.proceed': 'tiếp tục',
  'profile.payment.transaciton.confirm': 'xác nhận',
  'profile.payment.transaciton.confirmation': 'xác nhận',
  'profile.payment.transaciton.OTPverification': 'Xác minh OTP',
  'profile.payment.transaciton.OTPwarning': 'Nhận OTP từ số đăng ký {phone} để hoàn tất xác thực',
  'profile.payment.transaciton.completed': 'hoàn thành',
  'confirm-button-text': 'Xác nhận',
  'cancel-button-text': 'Hủy bỏ',
  'wallet.deposit.numberValidateReg': 'Vui lòng điền chính xác số Tài Khoản Ngân Hàng.',

  // 资金明细
  'profile.funding.process': 'Đang tiến hành',
  'profile.funding.success': 'Thành công',
  'profile.funding.fail': 'Thất bại',
  'profile.funding.all': 'Toàn bộ',
  'profile.funding.recharge': 'Nạp tiền',
  'profile.funding.rechargeByOther': 'Nạp hộ',
  'profile.funding.withdrawl': 'Rút tiền',
  'profile.funding.transfer': 'Chuyển khoản',
  'profile.funding.dividend': 'Tiền thưởng',
  'profile.funding.rebate': 'Hoàn trả',
  'profile.funding.addCoin': 'Tiền cộng thêm',
  'profile.funding.deleteCoin': 'Tiền khấu trừ',
  'profile.funding.cancel': 'Quay lại',
  'profile.funding.statement.updated': 'Điều chỉnh doanh thu hoàn tất',
  'profile.funding.statement.clear': 'Hết doanh thu',
  'profile.funding.loseUp.clear': 'Thua hết',
  'profile.funding.unknow': 'Không xác định',
  'profile.funding.today': 'Hôm nay',
  'profile.funding.yesterday': 'Hôm qua',
  'profile.funding.7days': '7 ngày',
  'profile.funding.30days': '30 ngày',
  'profile.funding.orderNumber': 'Số đơn hàng',
  'profile.funding.dealTime': 'Thời gian giao dịch',
  'profile.funding.dealType': 'Thể loại nạp rút tiền',
  'profile.funding.subtype': 'Nguồn nạp rút tiền',
  'profile.funding.status': 'Trạng thái',
  'profile.funding.startAmount': 'Số tiền ban đầu',
  'profile.funding.receivedAmount': 'NHẬP SỐ TIỀN',
  'profile.funding.receivedUSDT': 'Tiền ảo đã nhận',

  // 收款地址
  'profile.payment.tab.fiat': 'Ngân hàng',
  'profile.payment.tab.cryptocurrency': 'Tiền ảo',
  'profile.payment.crypto.tips.title': 'Có thể thêm tối đa 5 địa chỉ tiền ảo',
  'profile.payment.crypto.bank.save': 'Tiền ảo',
  'profile.payment.crypto.noData': 'Không có địa chỉ thanh toán',
  'profile.payment.crypto.tips': 'Vui lòng nhập tên đầy đủ của bạn, phải trùng với tên chủ tài khoản ngân hàng của bạn, nếu không khoản tiền gửi sẽ bị từ chối.',
  'profile.payment.crypto.title': 'Địa chỉ thanh toán',
  'profile.payment.crypto.cardHolder': 'Chủ thẻ',
  'profile.payment.crypto.placeholder.comfirmSignature': 'Hãy chắc chắn nhập tên chủ thẻ',
  'profile.payment.crypto.placeholder.signature': 'Vui lòng điền tên chủ thẻ',
  'profile.payment.crypto.openingBank': 'Hợp đồng',
  'profile.payment.crypto.openingBank.select': 'Địa chỉ ví',
  'profile.payment.crypto.alias': 'Bí danh',
  'profile.payment.crypto.pickCards': '',
  'profile.payment.crypto.addCards.success': 'Thêm địa chỉ thành công',
  'profile.payment.crypto.realName': 'Bí danh',
  'profile.payment.crypto.cardNnumber': 'Địa chỉ',
  'profile.payment.crypto.placeholder.cardNumber': 'Vui lòng điền địa chỉ',
  'profile.payment.crypto.placeholder.alias': 'Vui lòng điền bí danh',
  'profile.payment.crypto.tips.comfirm.title': 'Vui lòng xác nhận tất cả thông tin đã chính xác, thông tin sai lệch sẽ dẫn đến không thể nhận được tiền',
  'profile.payment.crypto.bank': 'Địa chỉ tiền ảo',
  'profile.payment.crypto.hello': 'Xin chào',
  'profile.payment.crypto.comfirm.notAddCard': 'Thêm địa chỉ tiền ảo',
  'profile.payment.crypto.comfirm.addCard': 'Xác nhận thêm địa chỉ tiền ảo',
  'profile.payment.crypto.tips.deleteCard.success': 'Xóa địa chỉ tiền ảo thành công',

  // 帮助中心
  'profile.helpCenter': 'Trung tâm trợ giúp',
  'profile.helpCenter.feedBack': 'Phản hồi ý kiến',
  'profile.helpCenter.aboutUs': 'Về chúng tôi',
  'profile.helpCenter.upperTitle': 'Xin chào thành viên thân yêu',
  'profile.helpCenter.lowerTitle': 'Chào mừng đến với trung tâm trợ giúp',
  'profile.helpCenter.descriptionTitle': 'Đường dây nóng 24/7, phục vụ tận tâm, luôn sẵn lòng phục vụ bạn mọi lúc mọi nơi ~',
  'profile.helpCenter.rechargeTutorial': 'HƯỚNG DẪN NẠP TIỀN',
  'profile.helpCenter.rechargeTutorialDescription': 'Lối chơi tuyệt vời,chờ bạn khám phá',
  'profile.helpCenter.withdrawTutorial': 'HƯỚNG DẪN RÚT TIỀN',
  'profile.helpCenter.withdrawTutorialDescritpion': 'Lối chơi tuyệt vời,chờ bạn khám phá',
  'profile.helpCenter.sportTutorial': 'Hướng dẫn cách chơi thể thao',
  'profile.helpCenter.sportTutorialDescription': 'Lối chơi tuyệt vời,chờ bạn khám phá',
  'profile.helpCenter.simBet': 'Đặt cược thể thao mô phỏng',
  'profile.helpCenter.simBetDescription': 'Không biết cược,nhanh đến xem nào',
  'profile.helpCenter.betRule': 'HƯỚNG DẪN CÁ CƯỢC THỂ THAO',
  'profile.helpCenter.betRuleDes': 'Hiểu rõ quy tắc,thuận tiện đặt cược',
  'profile.helpCenter.MOMO': 'MOMO',
  'profile.helpCenter.QRCodeWithdraw': 'Mã quét thẻ ngân hàng',
  'profile.helpCenter.BankAccountTransfer': 'Chuyển khoản thẻ ngân hàng',

  // 个人中心
  'profile.login': 'Đăng nhập để xem',
  'profile.balance': 'Số dư của tôi',
  'profile.userHeader.prefix': 'Xin chào',
  'profile.userHeader.userInfo': 'Thông tin cá nhân',
  'profile.userHeader.checkLogin': 'Đăng nhập để tận hưởng nhiều dịch vụ hơn',
  'profile.menu.fundDetails': 'Chi tiết',
  'profile.menu.fundDetails.details': 'Chi tiết về số tiền',
  'profile.menu.betRecord': 'Bản ghi\nđơn cược',
  'profile.menu.messageCenter': 'Trung tâm\ntin nhắn',
  'profile.menu.paymentAddress': 'Địa chỉ nhận\nkhoản',
  'profile.menu.vipCenter': 'Trung tâm\nVIP',
  'profile.menu.helpCenter': 'Trung tâm\ntrợ giúp',
  'profile.noticeBar.deposit': 'Hiện có một đơn đang tiến hành nạp',
  'profile.noticeBar.withdraw': 'Hiện có một đơn đang tiến hành rút',

  // 消息中心
  'profile.message.title': 'Thông tin',
  'profile.message.details': 'Chi tiết',
  'profile.message.announcement': 'Thông báo',
  'profile.message.announChange': 'Thông báo thay đổi tài khoản',

  // 修改登录密码
  'profile.modify.title': 'Thây đổi mật khẩu đăng nhập',
  'profile.modify.setPassword': 'Đặt mật khẩu mới',
  'profile.modify.currentPassword': 'Vui lòng nhập mật khẩu hiện tại',
  'profile.modify.passwordHint': 'Vui lòng nhập mật khẩu từ 8 đến 20 ký tự và số hoặc ký hiệu',
  'profile.modify.comfirmPassword': 'Vui lòng xác nhận mật khẩu mới',
  'profile.modify.comfirm': 'Xác nhận',
  'profile.modify.passwordFormat': 'Định dạng mật khẩu hiện tại không chính xác',
  'profile.modify.passwordDifferent': 'Nhập mật khẩu hai lần không khớp',
  'profile.modify.tips': 'Mật khẩu đã dược thây đổi,vui lòng đăng nhập lại',

  // 个人信息
  'profile.user.accountName': 'Tên tài khoản',
  'profile.user.registerDate': 'Ngày đăng ký',
  'profile.user.phoneNumber': 'Số điện thoại',
  'profile.user.phoneTips': 'Nếu bạn cần đổi số điện thoại đã liên kết, vui lòng liên hệ với bộ phận chăm sóc khách hàng',
  'profile.user.loginPassword': 'Mật khẩu đăng nhập',
  'profile.user.revise': 'Sửa đổi',
  'profile.user.verify': 'Xác minh SMS rút tiền',
  'profile.user.dateBirth': 'Ngày sinh',
  'profile.user.addDate': 'Thêm ngày để đảm bảo bạn từ 18 tuổi trở lên',
  'profile.user.editDate': 'Ngày sinh không thể sửa đổi lúc này',
  'profile.user.signOut': 'Đăng xuất',
  'profile.user.selectDate': 'Chọn ngày',

  // 优惠活动
  'promotion.activity.select': 'CHỌN',
  'promotion.activity.selected': 'ĐÃ CHỌN',
  'promotion.activity': 'Sự Kiện Khuyến Mãi',
  'promotion.details.claimBonus': 'Lãnh thưởng',
  'promotion.details.joinNow': 'Tham gia luôn',
  'promotion.details.notAllowed': 'Không thể tham gia',
  'promotion.details.notAchieved': '-Chưa đạt yêu cầu',
  'promotion.details.receiving': 'Đang lãnh thưởng',
  'promotion.details.reviewing': 'Đang kiểm duyệt',
  'promotion.details.rejected': 'Từ chối',
  'promotion.details.received': 'Đã lãnh thưởng',
  'promotion.details.invalid': 'Hết hạn',
  'promotion.detailsDesc.time': 'Thời gian khuyến mãi',
  'promotion.detailsDesc.longTermEffective': 'Lâu dài',
  'promotion.detailsDesc.howToParticipate': 'Đối tượng tham gia',
  'promotion.detailsDesc.allMembers': 'Tất cả hội viên',
  'promotion.detailsDesc.applicablePlatforms': 'Áp dụng sảnh',
  'promotion.detailsDesc.allPlatforms': 'Tất cả các sảnh',
  'promotion.detailsDeposit.depositPromotionFirstTitle': 'Khuyến mãi nạp đầu',
  'promotion.detailsDeposit.depositPromotionSecondTitle': 'Khuyến mãi nạp lại',
  'promotion.detailsDeposit.depositPromotionFirstLimit': 'Yêu cầu nạp tiền',
  'promotion.detailsDeposit.depositPromotionBonusRatio': 'Tỷ lệ tiền thưởng',
  'promotion.detailsDeposit.depositPromotionMaxAmount': 'Tiền thưởng tối đa',
  'promotion.detailsDeposit.depositPromotionBetFactor': 'Số Vòng cược',
  'promotion.detailsBonus.receivedSuccessful': 'Bạn đã nhận {amount} tiền thưởng ,Chỉ cần hoàn thành 3 vòng cược có thể rút',
  'promotion.detailsDeposit.depositBonusFirstTitle': 'Chi tiết tiết tiền thưởng nạp đầu',
  'promotion.detailsDeposit.depositBonusSecondTitle': 'Chi tiết tiết tiền thưởng nạp lần hai',
  'promotion.detailsDeposit.depositBonusFirstAmount': 'Số tiền nạp đầu',
  'promotion.detailsDeposit.depositBonusSecondAmount': 'Số tiền nạp\nLần hai',
  'promotion.detailsDeposit.realPerson': 'Casino/Điện tử/bắn cá',
  'promotion.detailsDeposit.otherVenues': 'Các Game khác',
  'promotion.detailsDeposit.multiple': '\nvòng cược',
  'promotion.detailsDeposit.depositBonusRatio': 'Tỷ lệ tiền thưởng',
  'promotion.detailsDeposit.depositBonusAmount': 'Số tiền thưởng',
  'promotion.detailsDeposit.depositBonusBet': 'Vòng cược yêu cầu',
  'promotion.detailsDeposit.orMore': 'Hoặc nhiều hơn',
  'promotion.detailsShareJackpot.time': 'Thời gian',
  'promotion.detailsShareJackpot.peopleNum': 'Số người chia sẻ quỹ giải thưởng',
  'promotion.detailsBonus.betAmountLimit': 'Số tiền cược yêu cầu',
  'promotion.detailsBonus.amountReward': 'Số tiền giải thưởng',
  'promotion.detailsBonus.negativeProfit': 'Lợi nhuận âm',
  'promotion.detailsBonus.bonusAmount': 'Số tiền giải thưởng',
  'promotion.detailsRebate.effectiveAmount': 'Số tiền cược hợp lệ',
  'promotion.detailsRebate.rebateRatio': 'Tỷ lệ hoàn trả',
  'promotion.detailsRebate.limit': 'Mức tối đa',
  'promotion.detailsBonusTips.welcome': 'Bạn {name} thân mến, Tổng số tiền đặt cược của bạn tuần trước là:',
  'promotion.detailsBonusTips.betBonuswelcome': 'Bạn {name} thân mến, lợi nhuận âm từ trò chơi đánh bạc của bạn ngày hôm qua:',
  'promotion.detailsBonusTips.validBetAmount': 'Tổng số tiền cược tuần trước của bạn là :',
  'promotion.detailsBonusTips.negativeProfit': 'Số tiền lợi nhuận âm trong game điện tử của bạn là :',
  'promotion.detailsRules.title': 'Điều khoản khuyến mãi',
  'promotion.summary.firstDepositTitle': 'Thành viên mới nạp tiền nhận thưởng lên tới 60%',
  'promotion.summary.rebateTitle': 'HOÀN TIỀN LÊN ĐẾN 1,2%',
  'promotion.summary.shareTheJackpotTitle': 'Tổng tiền thưởng hàng ngày lên đến 100Tr đồng',
  'promotion.summary.betBonusTitle': 'Hàng tuần thưởng cược lên đến 8.888Tr',
  'promotion.summary.slotBetBonusTitle': 'QUỸ CỨU TRỢ MÁY SLOT',
  'promotion.details.firstDepositSubTitle': 'Nạp tiền ngay và nhận thưởng',
  'promotion.details.rebateSubTitle': 'Thiết lập chuẩn mực mới',
  'promotion.details.shareTheJackpotSubTitle': 'Hãy cược ngay để có một phần thưởng',
  'promotion.details.betBonusSubTitle': 'Hãy đến cược ngay và dành chiến thắng',
  'promotion.details.slotBetBonusSubTitle': 'Mỗi ngày được nhận 1888!',
  'promotion.details.paly': 'Tham gia ngay',
  'promotion.details.duration': 'hoạt động lâu dài',
  'promotion.dailyCheckin.title': 'Điểm danh hàng ngày',
  'promotion.dailyCheckin.viewMore': 'Xem thêm',
  'promotion.dailyCheckin.day': 'Ngày',
  'promotion.dailyCheckin.already': 'Đã nhận',
  'promotion.dailyCheckin.pending': 'Đang chờ',
  'promotion.dailyCheckin.active': 'Nhận quà',
  'promotion.dailyCheckin.continuous': 'Đã điểm danh liên tục',
  'promotion.dailyCheckin.receivedAmount': 'Phần thưởng đã nhận',
  'promotion.dailyCheckin.rechargeAmount': 'Số tiền đã nạp',
  'promotion.dailyCheckin.rulesTitle': 'QUY TẮC ĐIỂM DANH VÀ NHẬN THƯỞNG',
  'promotion.dailyCheckin.popup.success.primaryText': 'Điểm danh thành công',
  'promotion.dailyCheckin.popup.success.subText': 'Xin chúc mừng, bạn đã nhận được số tiền thưởng là {bonus} VNĐ',
  'promotion.dailyCheckin.popup.success.button': 'ĐÃ HIỂU',
  'promotion.dailyCheckin.popup.warning.primaryText': 'Chưa đủ điều kiện',
  'promotion.dailyCheckin.popup.warning.subText': 'Bạn chưa đáp ứng đủ điều kiện nạp tiền trong ngày. Hãy nạp đủ số tiền cần thiết',
  'promotion.dailyCheckin.popup.warning.button': 'NẠP TIỀN NGAY',

  // 错误页 维护、访问限制等
  'errorPage.maintenance': 'hệ thống đang bảo trì',
  'errorPage.maintenance.timeLabel': 'Thời gian bảo trì :',
  'errorPage.maintenance.tips': 'Xin lỗi vì sự bất tiện gây ra liên hệ với bộ phận hỗ trợ khách hàng để biết thêm',
  'errorPage.maintenance.hasEnded': 'Kết thúc bảo trì',
  'errorPage.maintenance.backHome': 'Trở về trang đầu',

  'settings.home': 'Cài Đặt',
  'vip.detail.title': 'Chi tiết VIP',
  'vip.detail.enjoy': 'Đặc quyền VIP',
  'vip.detail.upgrades': 'Tiền thưởng thăng cấp',
  'vip.detail.birthday': 'Tiền thưởng sinh nhật',
  'vip.detail.tips': 'Nhận tiền thưởng thành công',
  'vip.detail.DrawDown': 'Nhận ngay',
  'vip.detail.firstHalfMonth': 'Lì xì nửa tháng đầu',
  'vip.detail.secondHalfMonth': 'Lì xì nửa tháng cuối',
  'vip.detail.monthlyRedPacket': 'Lì xì mỗi tháng',
  'vip.detail.holidayGift': 'Quà tặng ngày lễ',
  'vip.detail.exclusiveEvent': 'Sự kiện độc quyền',
  'vip.detail.fastMoneyOut': 'Rút tiền nhanh',
  'vip.detail.currentLevel': 'Cấp độ hiện tại',
  'vip.detail.maxLevel': 'Cấp độ cao nhất',
  'vip.detail.upgraded': 'Đợi lên cấp',
  'vip.detail.Unlock': 'Mở khóa ngay lập tức',
  'vip.detail.await': 'Nâng cấp để mở khóa',
  'vip.detail.currentDeposit': 'Chi tiết VIP',
  'vip.detail.activeBet': 'Cược hợp lệ',
  'vip.detail.theNext': 'Nâng cấp lên cấp tiếp theo',
  'vip.detail.accumulatedDeposit': 'Tiền gửi tích lũy (NDT)',
  'vip.detail.flowRequirement': 'Yêu cầu tiền cược (NDT)',
  'vip.detail.gradingWater90': 'duy trì vòng cược 90 ngày (NDT)',
  'vip.detail.vipLevel': 'Cấp VIP',
  'vip.detail.depositRequirement': 'Yêu cầu tiền nạp',
  'vip.detail.rights': 'Quyền lợi VIP',
  'vip.detail.text1': 'Phúc lợi có thể được phân phối cho',
  'vip.detail.welfareCenter': 'Trung tâm phúc lợi',
  'vip.detail.text2': 'Quy đổi',
  'vip.detail.upgradeExp': 'Điểm thăng cấp',
  'vip.detail.maintainExp': 'Điểm giữ cấp',
  'vip.detail.ruleDescription': 'VIP Điều khoản hướng dẫn',
  'vip.detail.bootonAllRight': 'Anh Hoàng Sports có quyền sửa đổi, dừng và có quyền giải thích cuối cùng.',
  'vip.detail.text3': 'Tiêu chuẩn thăng cấp',
  'vip.detail.text4':
    'Tiền nạp tích lũy và doanh thu tích lũy của thành viên VIP (Sau khi đặt cược được kết toán, hệ thống sẽ xác định số tiền đặt cược hợp lệ) đạt đủ yêu cầu của cấp độ tương ứng,có thể lên cấp VIP tương ứng, Thời gian thống kê thông tin thành viên VIP hệ thống hoạt động trong ngày từ 00: 00:00 bắt đầu tính.',
  'vip.detail.text5': 'Quy định thăng cấp',
  'vip.detail.text6': 'Cấp VIP đạt yêu cầu tương ứng, bạn có thể được lên cấp mỗi ngày.Tuy nhiên cấp độ VIP không được lên vượt cấp.',
  'vip.detail.text7': 'Yêu cầu duy trì cấp độ',
  'vip.detail.text8':
    'Thành viên VIP sau khi "Thay đổi cấp độ VIP", trong vòng 90 ngày, cần hoàn thành yêu cầu tiền cược tương ứng với cấp độ hiện tại. để tiếp tục duy trì cấp độ trong 90 ngày; Nếu trong thời gian này hoàn thành thăng cấp, yêu cầu duy trì cấp độ sẽ được tính lại dựa trên cấp độ hiện tại.',
  'vip.detail.text9': 'Tiêu chí rớt hạng',
  'vip.detail.text10':
    'Trong vòng 90 ngày nếu thành viên VIP không hoàn thành yêu cầu tiền cược cần thiết để duy trì cấp độ,hệ thống sẽ tự động hạ cấp thành viên xuống một cấp, các ưu đãi tương ứng cũng sẽ điều chỉnh xuống theo cấp độ mới.',
  'vip.detail.text11': 'Phúc lợi thăng cấp',
  'vip.detail.text12':
    'Phúc lợi thăng cấp sẽ được hệ thống tự động phân phát sau khi thành viên đạt đến cấp độ VIP tương ứng. mỗi thành viên VIP của mỗi cấp chỉ được nhận phúc lợi thăng cấp một lần (phúc lợi thăng cấp có thể rút sau 1 vòng cược',
  'vip.detail.text13': 'Lixi mỗi tháng',
  'vip.detail.text14':
    'thành viên VIP chỉ cần thực hiện ít nhất 1 lần nạp tiền thành công trong tháng trước, sẽ nhận được tiền lixi tháng trước tương ứng với cấp độ VIP vào ngày 1 của mỗi tháng.(Tiền lixi hàng tháng chỉ cần đạt 1 vòng cược là có thể rút tiền',
  'vip.detail.text15': 'Phúc lợi sinh nhật',
  'vip.detail.text16':
    'Tiền mừng sinh nhật sẽ được hệ thống tự động phát cho thành viên vào ngày sinh nhật thông qua Trung tâm VIP và phải được sử dụng trong vòng 30 ngày. Sau thời hạn sẽ hết hiệu lực và không được cấp lại. Số tiền mừng được xác định dựa trên cấp độ VIP vào ngày sinh nhật. Mỗi năm chỉ có thể nhận một lần và nếu thành viên không đặt ngày sinh nhật, sẽ coi như tự động từ chối (Tiền mừng sinh nhật chỉ cần 3 vòng cược là có thể rút tiền).',
  'vip.detail.remainingDays': 'Số ngày còn lại để duy trì cấp độ VIP',
  'profile.menu.vip0': 'Hoàn thành nạp đầu sẽ trở thành VIP',
  'vip.detail.days': ' ngày',

  'vip.detail.stat0': 'Chưa đạt yêu cầu',
  'vip.detail.stat2': 'Tham gia luôn',
  'vip.detail.stat4': 'Đang lãnh thưởng',
  'vip.detail.stat6': 'Đã nhận',
  'vip.detail.stat12': 'Hết hạn',

  'vip.detail.notUpToStandard': 'Chưa đạt yêu cầu',
  'vip.detail.joinAtOnce': 'Tham gia luôn',
  'vip.detail.claiming': 'Đang lãnh thưởng',
  'vip.detail.claimed': 'Đã nhận',
  'vip.detail.becameInvalid': 'Hết hạn',
  'vip.detail.has': 'có',
  'vip.detail.hasnot': 'Không có',
  'vip.detail.claimedOther': 'Đã nhận',
  'vip.detail.tablecontent':
    'Quà tặng VIP vô tận, thăng cấp VIP sẽ có thưởng thăng cấp. Lì xì hàng tháng, quà tặng ngày lễ, hoạt động độc quyền .v.v. Nhiều phần thưởng đang chờ bạn',

  'vip.home.birthday': 'Tiền thưởng sinh nhật',
  'vip.home.upgrade': 'Thưởng thăng cấp',
  'vip.home.midMonth': 'Lì xì giữa tháng',
  'vip.home.endMonth': 'Lì xì cuối tháng',
  'vip.home.to': ' để nâng lên cấp kế tiếp ',

  'settings.home.hello': 'Xin chào',
  'settings.home.register': 'Ngày tạo tài khoản ',
  'settings.home.balance': 'Số dư',
  'settings.home.transaction': 'Giao dịch',
  'settings.home.vip': 'VIP',
  'settings.home.activity': 'Hoạt động',
  'settings.home.address': 'TK ngân hàng',
  'settings.home.history': 'Lịch sử',
  'settings.home.username': 'Tên tài khoản',
  'settings.home.phoneNumber': 'SĐT di động',
  'settings.home.registerDate': 'Thời gian đăng ký',
  'settings.home.password': 'Mật khẩu',
  'settings.home.aboutUs': 'About us',
  'settings.home.helpCenter': 'Trung tâm hỗ trợ',
  'settings.home.level': 'Cấp',
  'settings.home.experienceLevel': 'Điểm tích lũy ',
  'settings.home.details': 'Chi tiết VIP',
  'settings.home.more': 'Tra xem thêm',
  'settings.home.special': 'Quyền lợi VIP',

  'settings.home.confirmPassword': 'Xác nhận mật khẩu mới',
  'settings.home.oldPassword': 'Mật khẩu cũ',
  'settings.home.mail': 'Hộp thư',
  'settings.home.welcomeBonus': '100% tiền thưởng chào mừng',
  'settings.home.betNow': 'Cược ngay',

  'settings.home.cashOutAmount': 'Số tiền rút',
  'settings.home.requestAmount': 'Số tiền nạp',
  'settings.home.cashInAmount': 'Số tiền nhận được',
  'settings.home.cashInAmountStr': 'Số tiền nhận được',
  'settings.home.cashInMethod': 'Phương thức nạp tiền',
  'settings.home.cashInAt': 'chuyển vào ',
  'settings.home.promotionAt': 'Nhận khoản',
  'settings.home.inData': 'Tiền mặt ghi ngày',
  'settings.home.outData': 'Ngày rút tiền',
  'settings.home.verify': 'Xác minh SMS rút tiền',

  'settings.home.help': 'Trợ giúp',
  'settings.home.birthday': 'Sinh nhật của bạn',
  'settings.home.unlock': 'Mở khóa',
  'settings.home.viewMore': 'Tra xem thêm',
  'settings.home.cashIn': 'Nạp Tiền',
  'settings.home.cashOut': 'Rút tiền ra',
  'settings.home.noMore': 'Không có nhiều hơn',
  'settings.home.noCotent': 'Không có nội dung hiển thị ở đây',

  // 客服页面
  'service.chat.loading': 'Đang kết nối với bộ phận CSKH...',
  helpText,
  pages
}
