// api

export enum userAPI {
  RegValidate = '/client/register/validate',
  GetValidateCode = '/client/register/validate/code',
  RegSubmit = '/client/register/submit',
  FetchUserInfo = '/user/getUserInfo',
  GetUserBalance = '/fund/userAccount/balance',
  LoginValidate = '/client/login/mobile/validate',
  GetLoginValidateCode = '/client/login/validate/code',
  PhoneLogin = '/client/login/mobile/submit',
  Login = '/client/login/account',
  ForgetValidate = '/client/forget/validate',
  GetForgetValidateCode = '/client/forget/validate/code',
  ForgetSubmit = '/client/forget/submitReset',
  UpdateBirthday = '/user/update/birthday',
  ResetPassword = '/user/reset/password',
  GetSmsCode = '/user/send/smsCode',
  ValidateSmsCode = '/user/validate/smsCode',
  SetWithdrawVal = '/user/update/withdrawVal',
  CheckStatus = '/user/check/status',
  Logout = '/user/logout',
  reportDomain = '/client/user/domain/report',
  getDomains = '/client/domain/list',
  UpdatePhone = '/user/update/telephone'
}

export interface IValidateRequest {
  /**
   * 行为验证参数
   */
  captchaOutput: string
  /**
   * 行为验证参数
   */
  genTime: string
  /**
   * 行为验证参数
   */
  lotNumber: string
  /**
   * 行为验证参数
   */
  passToken: string
  /**
   * 区号
   */
  telArea: string
  /**
   * 手机号
   */
  telephone: string | any

  [property: string]: any
}

export interface IRegSubmitRequest {
  /**
   * 临时token
   */
  accessToken: string
  /**
   * 密码
   */
  password: string
  /**
   * 区号
   */
  telArea: string
  /**
   * 手机号
   */
  telephone?: string
  /**
   * 用户名
   */
  userName: string
  /**
   * 手机验证码
   */
  velPhoneCode?: string
  /**
   * 代理code
   */
  agent?: string
  /**
   * 手动输入的代理code
   */
  promotionCode?: string
  [property: string]: any
}

export interface IRegSubmitResponse {
  id: number
  nickName: string
  telArea: string
  telephone: string
  /**
   * 用户token，后续的接口需要带在header
   */
  token: string
  userName: string

  [property: string]: any
}

export interface IPhoneLoginRequest {
  /**
   * 临时token
   */
  accessToken: string
  /**
   * 区号
   */
  telArea: string
  /**
   * 手机号
   */
  telephone: string
  /**
   * 手机验证码
   */
  velPhoneCode: string

  [property: string]: any
}

export interface ILoginRequest {
  /**
   * 行为验证参数
   */
  captchaOutput?: string
  /**
   * 行为验证参数
   */
  genTime?: string
  /**
   * 行为验证参数
   */
  lotNumber?: string
  /**
   * 行为验证参数
   */
  passToken?: string
  /**
   * 密码
   */
  password: string
  /**
   * 用户名
   */
  userName: string

  [property: string]: any
}

export interface ILoginResponse {
  email: string
  headUrl: string
  id: number
  nickName: string
  sex: number
  telArea: string
  telephone: string
  token: string
  userName: string
  vipLevel: number
  withdrawVal: number
}

export interface IForgetValidateRequest {
  /**
   * 三方参数
   */
  captchaOutput: string
  /**
   * 邮箱
   */
  email?: string
  /**
   * 三方参数
   */
  genTime: string
  /**
   * 三方参数
   */
  lotNumber: string
  /**
   * 三方参数
   */
  passToken: string
  /**
   * 区号
   */
  telArea?: string
  /**
   * 手机号
   */
  telephone?: string
  /**
   * 方式，1: 手机，2:邮箱，3:用户名
   */
  type?: number

  [property: string]: any
}

export type IForgetValidateResponse = string

export interface IForgetSubmitRequest {
  /**
   * 临时token
   */
  accessToken: string
  /**
   * 手机验证码
   */
  code: string
  /**
   * 新密码
   */
  password: string

  [property: string]: any
}

export interface IResetPassword {
  /**
   * 新密码
   */
  newPwd: string
  /**
   * 就密码
   */
  oldPwd: string

  [property: string]: any
}

export interface IUserBalance {
  /**
   * 余额
   */
  balance: number
  /**
   * 币种
   */
  coin: string
  /**
   * 锁定金额
   */
  lockAmount: number

  [property: string]: any
}

export interface IGetSmsCodeRequest {
  /**
   * 行为验证参数，测试环境输入:hp_geetest
   */
  captchaOutput: string
  /**
   * 行为验证参数，测试环境输入:hp_geetest
   */
  genTime: string
  /**
   * 行为验证参数，测试环境输入:hp_geetest
   */
  lotNumber: string
  /**
   * 行为验证参数，测试环境输入:hp_geetest
   */
  passToken: string
  /**
   * 类型，提现：withdraw
   */
  validateType: string

  [property: string]: any
}

export interface IValidateSmsCodeRequest {
  code: string
  validateType: string

  [property: string]: any
}

export interface IValidateUpdateBirdthdayRequest {
  birthday: number

  [property: string]: any
}

export interface ISetWithdrawValRequest {
  /**
   * 提现保护：0保护，1不保护，2资金锁定
   */
  withdrawVal: number
  /**
   * 短信验证码
   */
  code: string

  [property: string]: any
}

export interface IReportDomainRequest {
  /**
   * 域名
   */
  domain: string[]
}
export type IReportDomainResponse = boolean

export type IGetDomainsDomainResponse = {
  /**
   * 类型，类型,1:PC,2:H5,3:Image(静态资源),4:WS,5:代理域名,6:api域名
   */
  type?: number
  /**
   * 地址
   */
  url?: string
  [property: string]: any
}[]

export interface IupdatePhoneRequest {
  /**
   * 区号
   */
  telArea?: string
  /**
   * 手机号
   */
  telephone?: string
  /**
   * 手机验证码
   */
  velPhoneCode?: string
}
